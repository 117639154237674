import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, LoaderContainer, Tag, ToggleButton } from "common/components/atoms";
import { StakeholderDetails } from "common/components/organisms";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { UploadIcon, ViewCardsIcon, ViewListIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { downloadExcelFile } from "common/utils/download";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ResendEmailInviteModal from "../../../../common/components/organisms/stakeholder-details/components/ResendEmailInviteModal/ResendEmailInviteModal";
import InvitedStakeholdersIndicator from "../cap-table/Actual/InvitedStakeholdersIndicator/InvitedStakeholdersIndicator";
import classes from "../cap-table/common/actions/CaptableActions.module.scss";
import CardView from "./components/card-view/card-view";
import DeleteStakeholderModal from "./components/delete-stakeholder-modal/delete-stakeholder-modal";
import InviteStakeholderModal from "./components/invite-stakeholder-modal/invite-stakeholder-modal";
import InviteStakeholdersModal from "./components/invite-stakeholders-modal/invite-stakeholders-modal";
import StakeholderFormSidebar from "./components/stakeholder-form-sidebar";
import TableView from "./components/table-view/table-view";
import stakeholderTableClasses from "./components/table-view/table-view.module.scss";
import { useStakeholdersService } from "./stakeholders-management-service";
import { GetStakeholdersDTO } from "./types";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.stakeholdersManagement"),
  createTranslation(TranslationNS.common, "noAccess"),
];

type ViewModeOptions = "Table" | "Cards";

const StakeholdersManagement: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const { companyId = "0" } = useParams<{ companyId: string }>();

  const [isStakeholdersLoading, setIsStakeholdersLoading] = useState(true);
  const [selectedViewMode, setSelectedViewMode] = useState<ViewModeOptions>("Table");
  const [isSidebarFormOpen, setIsSidebarFormOpen] = useState(false);
  const [stakeholders, setStakeholders] = useState<GetStakeholdersDTO>([]);
  const [stakeholderId, setStakeholderId] = useState<number | undefined>(undefined);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const [removeStakeholderId, setRemoveStakeholderId] = useState<number | undefined>(undefined);
  const { getInvitedStakeholdersIndicatorData } = useStoreActions((actions) => actions.stakeholderInvitation);

  const { hasFullAccess } = useFeatures(FEATURES.stakeholdersManagement);
  const { getStakeholdersRequest, removeStakeholderRequest } = useStakeholdersService(companyId);

  useEffect(() => {
    getStakeholdersRequest().then((stakeholders) => {
      setStakeholders(stakeholders);
      setIsStakeholdersLoading(false);
    });
  }, [getStakeholdersRequest]);

  // Requests
  const fetchStakeholders = useCallback(async () => {
    setIsStakeholdersLoading(true);

    getInvitedStakeholdersIndicatorData(Number(companyId));

    setStakeholders(await getStakeholdersRequest());
    setIsStakeholdersLoading(false);
  }, [getStakeholdersRequest, getInvitedStakeholdersIndicatorData, companyId]);

  // FORM
  const handleOpenStakeholderSidebar = useCallback(async (id?: number) => {
    setIsSidebarFormOpen(true);
    setStakeholderId(id);
  }, []);

  const handleOpenDetailModal = useCallback(async (id?: number) => {
    setIsDetailModalOpen(true);
    setStakeholderId(id);
  }, []);

  const handleCloseStakeholderSidebar = useCallback(() => {
    setIsSidebarFormOpen(false);
  }, []);

  const stakeholderToRemove = useMemo(() => {
    return stakeholders.find((el) => el.stakeholderId === removeStakeholderId);
  }, [removeStakeholderId, stakeholders]);

  // callbacks

  const handleRemoveModalClose = useCallback(() => {
    setRemoveStakeholderId(undefined);
  }, []);

  const handleCloseDetailsModal = useCallback(() => {
    setIsDetailModalOpen(false);
  }, []);

  const handleRemoveStakeholder = useCallback(async () => {
    try {
      setIsStakeholdersLoading(true);

      const request = await removeStakeholderRequest(removeStakeholderId || 0);

      if (request) {
        await fetchStakeholders();

        handleRemoveModalClose();
      }
    } finally {
      setIsStakeholdersLoading(false);
    }
  }, [fetchStakeholders, handleRemoveModalClose, removeStakeholderId, removeStakeholderRequest]);

  const handleExport = useCallback(async () => {
    downloadExcelFile(`/api/export/stakeholders/${companyId}`, "Stakeholders list");
  }, [companyId]);

  return (
    <PageContent data-testid="stakeholders-management-page-test-id">
      <PageContent.Header>
        <div className="d-flex align-items-center">
          <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
          {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
        </div>

        <Button
          isFocusDisabled
          isDisabled={!hasFullAccess}
          data-testid="create-btn"
          tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
          tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          onClick={() => handleOpenStakeholderSidebar()}
        >
          {t("createStakeholderBtn")}
        </Button>
      </PageContent.Header>

      <div className="d-flex justify-content-end align-items-center mb-3">
        <Button
          size="s"
          variant="tertiary"
          className={cn(classes["export-btn"], "me-1")}
          iconRight={<UploadIcon className="ms-half" />}
          onClick={handleExport}
        >
          Export
        </Button>
        <ToggleButton
          firstVariant={{
            title: <ViewListIcon />,
            value: "Table",
          }}
          secondVariant={{ title: <ViewCardsIcon />, value: "Cards" }}
          selected={selectedViewMode}
          onClick={(selectedValue) => setSelectedViewMode(selectedValue as ViewModeOptions)}
          dataTestId="toggle-view-btn"
        />
      </div>

      <InvitedStakeholdersIndicator className="mb-3" />

      <LoaderContainer loading={isStakeholdersLoading}>
        {selectedViewMode === "Cards" && (
          <CardView
            stakeholders={stakeholders}
            handleOpenEditSidebar={handleOpenStakeholderSidebar}
            handleRemoveStakeholder={setRemoveStakeholderId}
            handleOpenDetailModal={handleOpenDetailModal}
          />
        )}

        {selectedViewMode === "Table" && (
          <div className={stakeholderTableClasses.tableContainer}>
            <TableView
              stakeholders={stakeholders}
              handleOpenEditSidebar={handleOpenStakeholderSidebar}
              handleOpenDetailModal={handleOpenDetailModal}
              handleRemoveStakeholder={setRemoveStakeholderId}
            />
          </div>
        )}

        <StakeholderDetails
          show={isDetailModalOpen}
          getDataLink={`/api/equity-management/cap-table/stakeholder-details/${stakeholderId}`}
          handleClose={handleCloseDetailsModal}
        >
          <StakeholderDetails.Details
            editButtonPress={(id) => {
              handleOpenStakeholderSidebar(id);
              handleCloseDetailsModal();
            }}
          />
          <StakeholderDetails.Content mode="contacts-view" />
        </StakeholderDetails>
      </LoaderContainer>

      <StakeholderFormSidebar
        isOpen={isSidebarFormOpen}
        onClose={handleCloseStakeholderSidebar}
        stakeholderId={stakeholderId}
        onCreateSuccess={fetchStakeholders}
      />

      <DeleteStakeholderModal
        isStakeholdersLoading={isStakeholdersLoading}
        removeStakeholderId={removeStakeholderId}
        stakeholderToRemove={stakeholderToRemove}
        handleRemoveModalClose={handleRemoveModalClose}
        handleRemoveStakeholder={handleRemoveStakeholder}
      />

      <InviteStakeholderModal onClose={fetchStakeholders} />
      <InviteStakeholdersModal onClose={fetchStakeholders} />
      <ResendEmailInviteModal sentCallback={fetchStakeholders} />
    </PageContent>
  );
};

export default StakeholdersManagement;
